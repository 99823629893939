<template>
  <div class="">
    <el-form :model="delivery" label-position="left" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="发货方式" prop="logis_type">
        <el-radio-group v-model="delivery.logis_type">
          <el-radio :label="1" v-if="orderInfo.order_goods_type!=2">需要物流</el-radio>
          <el-radio :label="2">无需物流</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="物流公司" prop="logis_id" v-if="delivery.logis_type==1">
          <el-select v-model="delivery.logis_id" placeholder="请选择快递" size="small">
            <el-option
                v-for="item in deliveryList"
                :key="item.id"
                :label="item.title"
                :value="item.id">
            </el-option>
            <el-option key="-1" label="其他快递" value="-1"></el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="快递名称" prop="logis_name" v-if="delivery.logis_type==1 && delivery.logis_id==-1">
        <el-input v-model="delivery.logis_name" placeholder="请输入快递名称"></el-input>
      </el-form-item>
      <el-form-item label="物流单号" prop="logis_no" v-if="delivery.logis_type==1">
        <el-input v-model="delivery.logis_no" placeholder="请输入快递单号"></el-input>
      </el-form-item>
      <el-form-item :label="orderInfo.order_goods_type==2?'虚拟发货备注':'发货备注'" prop="remark">
        <el-input type="textarea" rows="3" v-model="delivery.remark" placeholder="请输入备注"></el-input>
      </el-form-item>
      <el-form-item label="发货凭据">
        <SelectImgs :selectNum="3" :selectData="delivery.pics" :selectFunc="imgChoose" :deleteFunc="deleteFunc"></SelectImgs>
        <span style="color: #ccc;">最多3张图</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">确认发货</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SelectImgs from '../../components/SelectImgs'
export default {
  data() {
    return {
      deliveryList: [],
      delivery: {
        logis_type: '',
        logis_id: '',
        logis_name: '',
        logis_no: '',
        remark: '',
        pics: []
      },
      rules: {
        logis_type: [
          { required: true, message: '请选择物流方式', trigger: 'blur' }
        ],
        logis_id: [
          { required: true, message: '请选择物流公司', trigger: 'blur' }
        ],
        logis_name: [
          { required: true, message: '请输入快递名称', trigger: 'blur' }
        ],
        logis_no: [
          { required: true, message: '请输入快递单号', trigger: 'blur' }
        ]
      },
      orderInfo: false
    }
  },
  components: {
    SelectImgs
  },
  props: {
    orderId: Number,
    callback: Function
  },
  watch: {
    'orderId': {
      handler (newValue, oldValue) {
        this.getOrderInfo(newValue)
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getDeliveryList();
  },
  mounted() {
  },
  methods: {
    imgChoose(item) {
      var _this = this
      item.forEach(function (pic) {
        if(_this.delivery.pics.length < 3) {
          _this.delivery.pics.push(pic.pic)
        }
      })
    },
    deleteFunc(idx) {
      var imgs = JSON.parse(JSON.stringify(this.delivery.pics))
      imgs.splice(idx,1)
      this.delivery.pics = imgs
    },
    getDeliveryList() {
      this.$api.merchant.deliveryMerchantList({page_size: 1000}, res => {
        if(res.errcode==0) {
          this.deliveryList = res.data.data
        }
      })
    },
    getOrderInfo(id) {
      this.showLoading()
      this.$api.order.orderDetail({id:id}, res => {
        this.hideLoading()
        if(res.errcode==0) {
          this.orderInfo = res.data
        }
      })
    },
    submitForm() {
      var _this = this
      var params = this.delivery
      params.order_id = this.orderId
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if(this.orderInfo.order_goods_type==2 && params.remark=='') {
            this.fail('虚拟发货备注不能为空');
            return false
          }
          _this.$api.order.orderDelivery(params, res => {
            if (res.errcode == 0) {
              _this.success(res.errmsg)
              _this.callback()
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
</style>
